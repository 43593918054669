import React from 'react'
import '../LoginComponent.scss'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import styles from '../LoginComponent.module.scss'
import { ClassNamesFn } from 'classnames/types'

type emailStepType = {
  activeStep: number;
  email: any
  handleChange: any
  isEmailValid: ()=>void
  cx: ClassNamesFn;
}

const emailStep: React.FC<emailStepType> = ({ activeStep, cx, email, handleChange, isEmailValid }) => {

  const paymentMethodClass = cx({
    displayBlock: activeStep === 0,
    displayNone: activeStep !== 0
  });

  return (
    <div className={paymentMethodClass} >
      <div style={{ textAlign: 'center' }}>
        <b style={{ fontSize: '16px' }}>Olá, para continuar, <br /> digite seu email</b>
        <br />
        <TextField 
        value={email} 
        margin="dense"  
        onChange={(e) => { handleChange('email', e.target.value) }} 
        onKeyPress={(e) => { e.key === "Enter" && isEmailValid() }}
        classes={{ root: "textfieldLogin" }} 
        style={{ width: '90%', marginTop: '20px' }} 
        label="Email" 
        variant="outlined"
        />
        <br />
        <br />
        <Button color='primary' onClick={() => isEmailValid()} variant='contained' size='large' classes={{ root: styles.buttonLogin }}>Continuar</Button>
      </div>
    </div>
  )
}

export default emailStep
