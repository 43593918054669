import React, { useState } from 'react'
import styles from './LoginComponent.module.scss'
import Container from '@material-ui/core/Container'
import EmailStep from './components/emailStep'
import AccountStep from './components/accountStep'
import ForgotPassword from './components/forgotPassword'
import classNames from 'classnames/bind'

let cx = classNames.bind(styles);

type LoginType = {
   activeStep: number
   email: string
   password: string
   handleChange: (label: string, value: any) => void
   loginUser: () => void
   isEmailValid: () => void
   showPassword: boolean
   forgotPassword: () => void
   handleBack: () => void
   handleClickShowPassword: () => void
   loginDrawer?: boolean
}

const Login: React.FC<LoginType> = ({ activeStep,
   email,
   password,
   showPassword,
   loginUser,
   handleChange,
   isEmailValid,
   forgotPassword,
   handleBack,
   handleClickShowPassword,
   loginDrawer
}) => {

   const classNameLogin = cx({
      loginPage: !loginDrawer
   });

   return (
      <Container className={styles.container}>
         <div className={classNameLogin}>
            <EmailStep
               activeStep={activeStep}
               cx={cx}
               email={email}
               handleChange={handleChange}
               isEmailValid={isEmailValid}
            />
            <AccountStep
               activeStep={activeStep}
               cx={cx}
               email={email}
               password={password}
               loginUser={loginUser}
               handleChange={handleChange}
               handleClickShowPassword={handleClickShowPassword}
               forgotPassword={forgotPassword}
               showPassword={showPassword}
            />
            <ForgotPassword
               activeStep={activeStep}
               handleBack={handleBack}
               cx={cx}
            />
         </div>
      </Container>
   )
}

export default Login