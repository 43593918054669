import React from 'react'
import '../Footer.scss'
import styles from '../Footer.module.scss'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ChevronRight'
import { Link } from 'gatsby'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

type doubtsType = {
  expanded: string | false
  handleChange: (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => void
}

const doubts: React.FC<doubtsType> = ({ expanded, handleChange }) => {
  return (
    
            <ExpansionPanel square expanded={expanded === 'doubts'} onChange={handleChange('doubts')}>
            <ExpansionPanelSummary aria-controls="doubts-content" id="doubts" expandIcon={<ExpandMoreIcon color='primary' />} className={styles.expansionPanel} style={{paddingLeft: '32px'}}>
               DÚVIDAS
               </ExpansionPanelSummary>
            <ExpansionPanelDetails className={styles.expansionPanelDetails}>
               <List>
                  <ListItem button component={Link} to={'/'} className={styles.listItem}>
                     Como comprar
                  </ListItem>
                  <ListItem button component={Link} to={'/'} className={styles.listItem}>
                     Saiba o tamanho da sua aliança
                  </ListItem>
                  <ListItem button component={Link} to={'/'} className={styles.listItem}>
                     O que gravar nas alianças
                  </ListItem>
                  <ListItem button component={Link} to={'/'} className={styles.listItem}>
                     Emblemas anéis de formatura
                  </ListItem>
                  <ListItem button component={Link} to={'/'} className={styles.listItem}>
                     Central de ajuda
                  </ListItem>
               </List>
            </ExpansionPanelDetails>
         </ExpansionPanel>
  )
}

export default doubts
