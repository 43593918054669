import React from 'react'
import styles from '../DrawerCart.module.scss'
import Button from '@material-ui/core/Button'
import InputBase from '@material-ui/core/InputBase'
import InputMask from 'react-input-mask';
import { Link, navigate } from 'gatsby'
import Loading from '../../../../Loadings/Loading'
import { MICROSERVICE_CORREIOS } from '../../../../../../userglobals'
import { toggleDrawerRight, globalAlert } from '../../../../../store/actions'
import axios from 'axios'
import ClassNames from 'classnames/bind'


let cx = ClassNames.bind(styles);

type footerCartType = {
   subtotal: number
   totalPrice: number
   buttonCep: boolean
   handleButtonCep: () => void
   cep: string
   handleCep: (cep: React.SetStateAction<string>) => void
   buttonCupom: boolean
   handleButtonCupom: () => void
   cupom: string
   handleCupom: (cupom: React.SetStateAction<string>) => void
   setPriceFretes: React.Dispatch<React.SetStateAction<{
      active: boolean;
      prices: never[];
   }>>
   priceFretes: {
      active: boolean;
      prices: never[];
   }
   freteLoading: boolean
   setFreteLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const footerCart: React.FC<footerCartType> = ({
   totalPrice,
   subtotal,
   buttonCep,
   handleButtonCep,
   cep,
   handleCep,
   buttonCupom,
   handleButtonCupom,
   cupom,
   handleCupom,
   setPriceFretes,
   priceFretes,
   freteLoading,
   setFreteLoading
}) => {

   const freteLoadingClass = cx({
      displayBlock: freteLoading,
      displayNone: !freteLoading
   });

   const freteNotLoadingClass = cx({
      displayBlock: !freteLoading,
      displayNone: freteLoading
   });

   return (
      <div className={styles.footer}>
         <div className={styles.gridSubtotal}>
            Subtotal
            <div className={styles.displayInline}>
               R${`${subtotal.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`}
            </div>
         </div>
         <div className={styles.gridCep}>
            {
               buttonCep ?
                  <div className={styles.displayFlex}>
                     <div className={freteNotLoadingClass}>
                        <InputMask
                           mask="99999-999"
                           disabled={false}
                           maskChar=" "
                           onChange={(e) => handleCep(e.target.value)}>
                           {
                              () =>
                                 <InputBase
                                    style={{ width: '100px' }}
                                    inputProps={{ style: { padding: 0, fontSize: '14px' } }}
                                    type="text"
                                    placeholder="00000-000"
                                 />
                           }
                        </InputMask>
                        <Button
                           variant='text'
                           color='primary'
                           className={styles.buttonOkCep}
                           onClick={() => {
                              setFreteLoading(true)
                              const payload = {
                                 sCepOrigem: "07252971",
                                 sCepDestino: cep.replace(/[^\w\s]/gi, ''),
                                 nVlPeso: "0.1",
                                 nCdFormato: 1,
                                 nVlComprimento: 16,
                                 nVlAltura: 7,
                                 nVlLargura: 11,
                                 nVlDiametro: 0,
                              }

                              axios.post(MICROSERVICE_CORREIOS + 'calc-ship-price', { ...payload })
                                 .then(response => {
                                    //console.log(response.data)
                                    setPriceFretes({ active: true, prices: response.data });
                                    setFreteLoading(false);
                                 })
                                 .catch(err => {
                                    setFreteLoading(false);
                                    setPriceFretes({ active: false, prices: [] });
                                    globalAlert('Erro ao calcular o frete.')
                                 })
                           }
                           }
                        >
                           OK
                        </Button>
                     </div>
                     <div className={freteLoadingClass}>
                        <Loading widthContainer={20} heightContainer={20} widthLoading={20} heightLoading={20} />
                     </div>
                  </div> :
                  <Button disableTouchRipple onClick={handleButtonCep} className={styles.buttonCep}>
                     Digite seu CEP
                  </Button>
            }
            <a href={'http://www.buscacep.correios.com.br/sistemas/buscacep/buscaCepEndereco.cfm'} target="_blank" className={styles.consultCep}>
               Não sei meu CEP
            </a>
         </div>
         {
            (priceFretes.active) && (!freteLoading) &&
            <div className={styles.gridFretes}>

               {priceFretes.prices.map((service: any, idx: any) => {
                  return (<div key={idx}><b>{service.servico}</b> - em até {service.PrazoEntrega} dias úteis - <b>{service.Valor}</b> </div>)
               })}

            </div>
         }
         <div className={styles.gridCupom}>
            {
               buttonCupom ?
                  <div className={styles.textfieldCep}>
                     <InputBase
                        inputProps={{ style: { padding: 0, fontSize: '14px' } }}
                        type="text"
                        placeholder="DIGITE SEU CUPOM"
                        onChange={(e) => handleCupom(e.target.value)}
                     />
                     <Button variant='text' color='primary' className={styles.buttonsFooter} >
                        <div>Aplicar</div>
                     </Button>
                  </div> :
                  <>
                     <span>VOCÊ TEM CUPOM?</span>
                     <Button variant='text' color='primary' className={styles.buttonCupom} onClick={handleButtonCupom}>
                        CLIQUE AQUI
                     </Button>
                  </>
            }
         </div>
         <div className={styles.gridTotal}>
            TOTAL
            <div className={styles.totalPrice}>
               R${`${subtotal.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`}
            </div>
         </div>
         <Button component={Link} to={'/checkout/'} onClick={toggleDrawerRight} size='large' disableTouchRipple fullWidth className={styles.buttonFinish}>
            Finalizar pedido
         </Button>
      </div>
   )
}

export default footerCart
