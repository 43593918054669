import React from 'react'
import '../Footer.scss'
import styles from '../Footer.module.scss'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

type newsType = {
   nameId: string
}

const news: React.FC<newsType> = ({nameId}) => {
   return (
      <div className={styles.news}>
         <div>
            <h3 className={styles.h3}>FIQUE POR DENTRO DAS NOVIDADES</h3> <br />
            <form className={styles.form}>
               <TextField
                  classes={{ root: 'textfield' }}
                  id={nameId}
                  placeholder="Digite seu email"
                  inputProps={{ style: { textAlign: 'center' } }}
                  variant="outlined"
                  margin='dense'
                  autoComplete='off'
                  fullWidth
               />
               <Button
                  className={styles.boxShadowNone}
                  variant="contained"
                  color="primary"
                  fullWidth
               >
                  Enviar
               </Button>
            </form>
         </div>
      </div>
   )
}

export default news
