import React from "react"
import styles from "../CategoryDrawerContent.module.scss"
import { useSelector } from "react-redux"
import { toggleDrawerLogin, toggleDrawerLeft, logout, setSelectedOrder } from "../../../../../store/actions"
import { navigate } from 'gatsby'
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined"
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined"
import VJIcon from "../../../../../images/coracao-vj.inline.svg"

type DrawerIconsType = {}

const DrawerIcons: React.FC<DrawerIconsType> = () => {
  const userAuth = useSelector((state: any) => state.userAuth)
  const userData = useSelector((state: any) => state.userData)
  //console.log(userAuth)
  return (
    <div className={styles.gridIcons}>
      {userAuth.auth ? (
        <div className={styles.icons}>
          <div onClick={() => {navigate('/minha-conta/'); toggleDrawerLeft();}} className={styles.icon}>
            <AccountCircleOutlinedIcon width={23} height={23} color="primary" />
          </div>
          <span style={{ fontSize: "11px" }}>
            Olá,{" "}
            <b style={{ textTransform: "uppercase" }}>{userData.firstname}</b>
          </span>
          <div onClick={() => {logout(); navigate('/'); toggleDrawerLeft(); logout();}} style={{fontSize: '12px', color: '#e8927c', fontWeight: 'bold'}}>Sair</div>
        </div>
      ) : (
        <div onClick={()=>{toggleDrawerLogin()}} className={styles.icons}>
          <div className={styles.icon}>
            <AccountCircleOutlinedIcon width={23} height={23} color="primary" />
          </div>
          <span style={{ fontSize: "11px" }}>
            ENTRAR OU <br /> CRIAR CONTA
          </span>
        </div>
      )}
      <div className={styles.icons}>
        <div onClick={() => {navigate('/minha-conta/pedidos/'); setSelectedOrder(null); toggleDrawerLeft();}} className={styles.icon}>
          <LocalMallOutlinedIcon width={23} height={23} color="primary" />
        </div>
        <span style={{ fontSize: "11px" }}>
          MEUS
          <br /> PEDIDOS
        </span>
      </div>
      <div onClick={() => {navigate('/minha-conta/lista-de-desejos/'); toggleDrawerLeft();}} className={styles.icons}>
        <div className={styles.icon}>
          <VJIcon width={23} height={23} fill="#e8927c" />
        </div>
        <span style={{ fontSize: "11px" }}>LISTA DE DESEJOS</span>
      </div>
    </div>
  )
}

export default DrawerIcons
