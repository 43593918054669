import React from 'react'
import '../Footer.scss'
import styles from '../Footer.module.scss'
import Social from './social'


type contactUsType = {
}

const contactUs: React.FC<contactUsType> = () => {

   return (
      <div className={styles.contactUs}>
         <div style={{textAlign: 'center'}}>
            <h3 className={styles.h3}>FALE CONOSCO</h3> <br />
            <div className={styles.divContato}>
               <h3 className={styles.phoneNumber}>(11) 3522-4808</h3>
            </div><br />
            De Seg. à Sex. das 8h às 18h <br />
            sac@virtualjoias.com
               <Social />
         </div>
      </div>
   )
}

export default contactUs
