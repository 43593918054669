import React from "react"
import Img from "react-image"
import Loading from "../Loadings/Loading"
import TrackVisibility from "react-on-screen"

type SingleImageComponentType = {
  imgUrl: string
  altImg: string
  widthContainer?: number
  heightContainer?: number
  widthLoading?: number
  heightLoading?: number
  _class?: string | undefined
}

const SingleImageComponent: React.FC<SingleImageComponentType> = ({
  imgUrl,
  altImg,
  widthContainer,
  heightContainer,
  widthLoading,
  heightLoading,
  _class,
}) => {
  return (
    <TrackVisibility once partialVisibility style={{lineHeight: 'normal', display: 'flex', justifyContent: 'center'}}>
      {({ isVisible }) =>
        isVisible && (
          <Img
            src={imgUrl}
            alt={altImg}
            className={_class}
            loader={
              <Loading
                widthContainer={widthContainer}
                heightContainer={heightContainer}
                widthLoading={widthLoading}
                heightLoading={heightLoading}
              />
            }
            unloader={<Loading
              widthContainer={widthContainer}
              heightContainer={heightContainer}
              widthLoading={widthLoading}
              heightLoading={heightLoading}
            />}
          />
        )
      }
    </TrackVisibility>
  )
}

export default SingleImageComponent
