import React from 'react'
import '../Footer.scss'
import styles from '../Footer.module.scss'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ChevronRight'
import { Link } from 'gatsby'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'


type myAccountMobileType = {
  handleChange: (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => void;
  expanded: string | boolean;
}

const myAccountMobile: React.FC<myAccountMobileType> = ({ handleChange, expanded }) => {
  return (
    <ExpansionPanel square expanded={expanded === 'myAccount'} onChange={handleChange('myAccount')}>
      <ExpansionPanelSummary aria-controls="myAccount-content" id="myAccount" expandIcon={<ExpandMoreIcon color='primary' />} className={styles.expansionPanel} style={{ paddingLeft: '32px' }}>
        MINHA CONTA
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={styles.expansionPanelDetails}>
        <List>
          <ListItem button component={Link} to={'/minha-conta/'} className={styles.listItem}>
            Meu painel
                  </ListItem>
          <ListItem button component={Link} to={'/minha-conta/pedidos/'} className={styles.listItem}>
            Meus pedidos
                  </ListItem>
          <ListItem button component={Link} to={'/minha-conta/enderecos/'} className={styles.listItem}>
            Meus endereços
                  </ListItem>
        </List>
      </ExpansionPanelDetails>
    </ExpansionPanel>

  )
}

export default myAccountMobile