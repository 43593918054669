import React from 'react'
import "../CategoryDrawerContent.module.scss"
import TreeItem from '@material-ui/lab/TreeItem'
import { CategoryType } from 'types'
import styles from '../CategoryDrawerContent.module.scss'
import { Link } from 'gatsby'
import { toggleDrawerLeft } from "../../../../../store/actions"

type NestedCategoryType = {
  category: CategoryType;
  expanded: string[];
}

const NestedCategory: React.FC<NestedCategoryType> = ({ category, expanded }) => {

  if (category.children_data.length !== 0) {
    return (
      <TreeItem
        classes={
          {
            root: (category.level === 2) ? styles.treeItem : styles.treeItem2,
            content: (expanded.some(x => x === `${category.id}`)) ? styles.expanded3 : (category.level === 2) ? styles.expanded : styles.expanded2,
            label: (expanded.some(x => x === `${category.id}`)) ? styles.labelReset2 : styles.labelReset,
            group: styles.resetGroupItem,
            iconContainer: styles.selected
          }
        }

        key={category.id}
        nodeId={`${category.id}`}
        label={
          category.name
        }>
        {category.children_data.map(
          subCategory => {
            return (<NestedCategory key={subCategory.id} category={subCategory} expanded={expanded} />)
          }
        )}
        {category.level === 2
          &&
          <Link className={styles.resetLink} onClick={toggleDrawerLeft} to={"/" + category.slug + "/"}>
            <TreeItem
              nodeId={`verTudo-${category.id}`}
              label={"VER TUDO"}
              classes={
                {
                  root: styles.treeItem2,
                  content: styles.expanded2,
                  label: styles.labelVerTudo
                }
              } />
          </Link>
        }

      </TreeItem>

    )
  } else {
    return (
      <Link className={styles.resetLink} onClick={toggleDrawerLeft} to={"/" + category.slug + "/"}>
        <TreeItem
          classes={
            {
              root: styles.treeItem2,
              content: (category.level === 2) ? styles.expanded : styles.expanded2,
              label: (category.level === 2) ? styles.labelReset4 : styles.labelReset3,
              group: styles.resetGroupItem
            }
          }
          key={category.id}
          nodeId={`${category.id}`}
          label={category.name}>
        </TreeItem>
      </Link>
    )
  }
}

export default NestedCategory
