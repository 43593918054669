import React from "react"
import LoadingSVG from '../../images/loading.inline.svg'

type LoadingType = {
  widthContainer?: number | string;
  heightContainer?: number | string;
  widthLoading?: number;
  heightLoading?: number
}

const Loading: React.FC<LoadingType> = ({ widthContainer, heightContainer, widthLoading, heightLoading }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: widthContainer, height: heightContainer }}>
      <div>
        <LoadingSVG />
      </div>
    </div>
  )
}

export default Loading
