import React from "react"
import '../Footer.scss'
import styles from '../Footer.module.scss'
import SingleLazyImageComponent from '../../ImageComponent/SingleLazyImageComponent'
import { MAGENTO_URL } from '../../../../userglobals'
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import Grid from "@material-ui/core/Grid"
import classNames from "classnames"

type secureWebsiteType = {
}

const secureWebsite: React.FC<secureWebsiteType> = () => {
  const siteSeguro = `${MAGENTO_URL}media/icons/google-siteseguro.svg`
  const siteBlindado = `${MAGENTO_URL}media/icons/site-blindado.svg`
  const empresaIndicada = `${MAGENTO_URL}/media/icons/selo_reclameaqui.svg`
  const lojaConfiavel = `${MAGENTO_URL}media/icons/yourviews.svg`

  return (
    <>
      <div>
        <ExpansionPanelSummary
          id="secureWebsite"
          className={styles.expansionPanel}
        >
          SITE SEGURO
        </ExpansionPanelSummary>
        <div className={styles.stamps}>
          <Grid item xs={6} className={styles.leftStamps}>
            <SingleLazyImageComponent
              imgUrl={siteSeguro}
              altImg={"Site Seguro"}
            />
          </Grid>
          <Grid item xs={6} className={styles.rightStamps}>
            <SingleLazyImageComponent
              imgUrl={siteBlindado}
              altImg={"Site Blindado"}
            />
          </Grid>
          <Grid item xs={6} className={styles.leftStamps}>
            <SingleLazyImageComponent
              imgUrl={empresaIndicada}
              altImg={"Empresa Indicada"}
            />
          </Grid>
          <Grid item xs={6} className={styles.rightStamps}>
            <SingleLazyImageComponent
              imgUrl={lojaConfiavel}
              altImg={"Loja Confiável"}
            />
          </Grid>
        </div>
      </div>

    </>
  )


}

export default secureWebsite
