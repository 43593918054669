import React from 'react'
import styles from './LoginDrawerContent.module.scss'
import Login from '../../../Login/Login'
import Button from '@material-ui/core/Button'
import { toggleDrawerLeft } from "../../../../store/actions"
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'

type LoginDrawerContentType = {

}

const LoginDrawerContent: React.FC<LoginDrawerContentType> = () => {
  return (
    <div className={styles.drawerLoginMain}>
      <div className={styles.gridMenu}>
        <div className={styles.closeButton}></div>
        <div className={styles.menuPrincipal}>
          <span>MENU</span>
        </div>
        <div className={styles.closeButton}>
          <Button
            onClick={toggleDrawerLeft}
            disableTouchRipple
            color="primary"
            style={{ minWidth: "20px", height: "20px", padding: 0 }}
          >
            <ArrowBackRoundedIcon fontSize="small" />
          </Button>
        </div>
      </div>

      <div className={styles.loginContentMain}>
        <Login loginDrawer={true} />
      </div>
      
      <div className={styles.divDuvidas}>DÚVIDAS</div>
    </div>
  )
}

export default LoginDrawerContent
