import React from "react"
import Img from "react-image"
import Loading from "../Loadings/Loading"
import TrackVisibility from "react-on-screen"

type SingleImageComponentType = {
  imgUrl: string
  altImg: string
  widthContainer?: number | string
  heightContainer?: number
  widthLoading?: number
  heightLoading?: number
  _class?: string | undefined
}

const SingleImageComponent: React.FC<SingleImageComponentType> = ({
  imgUrl,
  altImg,
  widthContainer,
  heightContainer,
  widthLoading,
  heightLoading,
  _class,
}) => {
  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: heightContainer, width: widthContainer}}>
      <Img
        src={imgUrl}
        alt={altImg}
        className={_class}
        loader={
          <Loading
            widthContainer={widthContainer}
            heightContainer={heightContainer}
            widthLoading={widthLoading}
            heightLoading={heightLoading}
          />
        }
        unloader={
          <Loading
            widthContainer={widthContainer}
            heightContainer={heightContainer}
            widthLoading={widthLoading}
            heightLoading={heightLoading}
          />
        }
      />
    </div>
  )
}

export default SingleImageComponent
