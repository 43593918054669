import React from 'react'
import '../Footer.scss'
import styles from '../Footer.module.scss'
import classNames from 'classnames/bind'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ChevronRight'
import { Link } from 'gatsby'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'


type institutionalMobileType = {
  handleChange: (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => void;
  expanded: string | boolean;
}

const institutionalMobile: React.FC<institutionalMobileType> = ({ handleChange, expanded }) => {
  return (
    
        <div>
          <ExpansionPanel square expanded={expanded === 'institutional'} onChange={handleChange('institutional')} >
            <ExpansionPanelSummary aria-controls="institutional-content" id="institutional" expandIcon={<ExpandMoreIcon color='primary' />} className={styles.expansionPanel} style={{ paddingLeft: '32px' }}>
              INSTITUCIONAL
               </ExpansionPanelSummary>
            <ExpansionPanelDetails className={styles.expansionPanelDetails}>
              <List>
                <ListItem button component={Link} to={'/'} className={styles.listItem}>
                  Quem somos
                     </ListItem>
                <ListItem button component={Link} to={'/'} className={styles.listItem}>
                  Fale conosco
                     </ListItem>
                <ListItem button component={Link} to={'/'} className={styles.listItem}>
                  Segurança
                     </ListItem>
                <ListItem button component={Link} to={'/'} className={styles.listItem}>
                  Política de privacidade
                     </ListItem>
                <ListItem button component={Link} to={'/'} className={styles.listItem}>
                  Pagamentos
                     </ListItem>
                <ListItem button component={Link} to={'/'} className={styles.listItem}>
                  Prazos e formas de envio
                     </ListItem>
                <ListItem button component={Link} to={'/'} className={styles.listItem}>
                  Garantia e trocas
                     </ListItem>
                <ListItem button component={Link} to={'/'} className={styles.listItem}>
                  Política de devolução
                     </ListItem>
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
  )
}

export default institutionalMobile