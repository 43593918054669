import React, { useState } from "react"
import styles from './DrawerLeft.module.scss'
import { useSelector } from "react-redux"
import Drawer from "@material-ui/core/Drawer"
import { toggleDrawerLeft } from '../../../store/actions'
import CategoryDrawerContent from './CategoryDrawerContent/CategoryDrawerContent'
import LoginDrawerContent from './LoginDrawerContent/LoginDrawerContent'


type DrawerCategoryType = {
  drawerLeftOpen: boolean

}

const LeftDrawerSwitch:React.FC<any> = ({drawerCategoryOpen,drawerLoginOpen}) => {
  if(drawerCategoryOpen){
    return <CategoryDrawerContent/>
  }else if(drawerLoginOpen){
    return <LoginDrawerContent /> 
  }
  return null
}

const DrawerLeft: React.FC<DrawerCategoryType> = ({drawerLeftOpen}) => {
  
  const drawerCategoryOpen = useSelector((state: any) => state.drawers.isCategoryOpen)
  const drawerLoginOpen = useSelector((state: any) => state.drawers.isLoginOpen)
  //console.log(drawerLeftOpen)
  return (
    <Drawer
      anchor={"left"}
      open={drawerLeftOpen}
      onClose={toggleDrawerLeft}
      data-testid="drawer-category"
      classes={{
        paper: styles.mainContainer,
      }}
    >
      <LeftDrawerSwitch drawerCategoryOpen={drawerCategoryOpen} drawerLoginOpen={drawerLoginOpen}/>
    </Drawer>
  )
}

export default DrawerLeft
