import React from 'react'
import '../Footer.scss'
import styles from '../Footer.module.scss'
import SingleLazyImageComponent from '../../ImageComponent/SingleLazyImageComponent'
import { MAGENTO_URL } from '../../../../userglobals'

type socialType = {

}

const social: React.FC<socialType> = () => {
  const facebookIcon = `${MAGENTO_URL}media/icons/facebook-icon.svg`;
  const instagramIcon = `${MAGENTO_URL}media/icons/Instagram-icon.svg`;
  const youtubeIcon = `${MAGENTO_URL}media/icons/youtube-icon.svg`;
  const pinterestIcon = `${MAGENTO_URL}media/icons/pinterest-icon.svg`;
  const twitterIcon = `${MAGENTO_URL}media/icons/twitter-icon.svg`;

  return (
    <div className={styles.social}>
      Vem fazer uma social com a gente
      <div className={styles.socialIcons}>
        <a href={'https://www.facebook.com/virtualjoias/'} rel="noopener" target="_blank" className={styles.socialIcons}>
          <SingleLazyImageComponent imgUrl={facebookIcon} altImg={'Facebook'} widthContainer={15} heightContainer={29} widthLoading={20} heightLoading={20} />
        </a>
        <a href={'https://www.instagram.com/virtualjoias/'} rel="noopener" target="_blank" className={styles.socialIcons}>
          <SingleLazyImageComponent imgUrl={instagramIcon} altImg={'Instagram'} widthContainer={15} heightContainer={29} widthLoading={20} heightLoading={20} />
        </a>
        <a href={'https://www.youtube.com/virtualjoias/'} rel="noopener" target="_blank" className={styles.socialIcons}>
          <SingleLazyImageComponent imgUrl={youtubeIcon} altImg={'Youtube'} widthContainer={15} heightContainer={29} widthLoading={20} heightLoading={20} />
        </a>
        <a href={'https://br.pinterest.com/virtualjoias/'} rel="noopener" target="_blank" className={styles.socialIcons}>
          <SingleLazyImageComponent imgUrl={pinterestIcon} altImg={'Pinterest'} widthContainer={15} heightContainer={29} widthLoading={20} heightLoading={20} />
        </a>
        <a href={'https://twitter.com/virtualjoias/'} rel="noopener" target="_blank" className={styles.socialIcons}>
          <SingleLazyImageComponent imgUrl={twitterIcon} altImg={'Twitter'} widthContainer={15} heightContainer={29} widthLoading={20} heightLoading={20} />
        </a>
      </div>
    </div>
  )
}

export default social