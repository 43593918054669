import React from "react"
import styles from "./Navbar.module.scss"
import { useSelector } from "react-redux"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Container from "@material-ui/core/Container"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/MenuRounded"
import { Link } from "gatsby"
import LogoNavbarVJ from "../../images/logoVj.inline.svg"
import SearchIcon from "@material-ui/icons/Search"
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined"
import classNames from "classnames"
import Badge from "@material-ui/core/Badge"
import Search from '../Search/SearchNavbar'
import {
  toggleDrawerLeft,
  toggleDrawerRight,
  toggleSearch
} from "../../store/actions"

type NavbarMobileType = {}

const Navbar: React.FC<NavbarMobileType> = () => {
  const cart = useSelector((state: any) => state.cart);
  const isSearchOpen = useSelector((state: any) => state.isSearchOpen);

  let count = 0;

  cart.map((item: any) => {
    count = count + (+item.visibleCartItem.qty)
  });

  return (
    <AppBar
      position="static"
      color="secondary"
      style={{ boxShadow: "none" }}
    >
      <Toolbar classes={{ root: styles.toolbar }}>
        <Container className={styles.container}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: '100%'
            }}
          >
            <IconButton

              edge="start"
              aria-label="menu"
              onClick={toggleDrawerLeft}
              data-testid="button-toggle-category-drawer"
            >
              <MenuIcon />
            </IconButton>
            <div className={styles.logoWrapper}>
              <Link to="/" aria-current="page" rel="Go to home page">
                <LogoNavbarVJ fill="#e8927c" alt="Logomarca da Virtual Joias" />
              </Link>
            </div>
            <div className={styles.navButtons}>
              <IconButton
                className={classNames(styles.button)}
                style={isSearchOpen ? { display: 'none' } : undefined}
                edge="end"
                aria-label="search"
                data-testid="button-toggle-search-drawer"
                onClick={toggleSearch}
              >
                <SearchIcon />
              </IconButton>
              <IconButton
                className={styles.button}
                edge="start"
                aria-label="cart"
                data-testid="button-toggle-cart-drawer"
                onClick={toggleDrawerRight}
              >
                <Badge
                  badgeContent={count}
                  color="primary"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  classes={{ anchorOriginBottomRightCircle: styles.badge }}
                  overlap="circle"
                  showZero
                >
                  <LocalMallOutlinedIcon />
                </Badge>
              </IconButton>
            </div>
          </div>

          {
            isSearchOpen && <Search count={count} />
          }

        </Container>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
