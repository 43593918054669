import React, {useState} from 'react'
import {
    ContactUs,
    Institutional,
    Doubts,
    MyAccount,
    Copyright,
    PaymentMethods,
    ShipmentMethods,
    SecureWebsite,
    News
  } from '../components'
import Container from '@material-ui/core/Container';

type FooterType = {

}

const Footer: React.FC<FooterType> = () => {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Container >
          <ContactUs />
          <News nameId={'emailDesk'}/>
          <Institutional expanded={expanded} handleChange={handleChange} />
          <Doubts expanded={expanded} handleChange={handleChange} />
          <MyAccount expanded={expanded} handleChange={handleChange} />
          <PaymentMethods />
          <ShipmentMethods />
          <SecureWebsite />
          <Copyright />
        </Container>
    )
}

export default Footer
