import { MICROSERVICE_MERCADOPAGO } from '../../../../userglobals'
import { navigate } from 'gatsby'
import axios from 'axios'
import { setBilletUrl, setLoadingTrue, setLoadingFalse, globalAlert } from '../../../store/actions'

let token = "";

export const getToken = (creditCardPaymentInfo: any, setCreditCardPaymentInfo: (arg0: any) => void, setCardVisible: (arg0: boolean) => void, handleNext: any, setErrorsCreditCardForm: any) => {
   const $form = document.getElementById("pay");
   //console.log($form);
   let resposta: any[] = [];
   
   setLoadingTrue();
   window.Mercadopago.createToken($form, (status: any, response: any) => {
      if (response.status == 'active') {
         console.log(response);
         console.log(status);
         //sdkResponseHandler(status, response, setCardVisible, creditCardPaymentInfo, setCreditCardPaymentInfo)
         token = response.id
         const copyPaymentInfo = { ...creditCardPaymentInfo };
         copyPaymentInfo['token'] = response.id;
         setCardVisible(true);
         setCreditCardPaymentInfo(copyPaymentInfo);
         setErrorsCreditCardForm([]);
         handleNext();

      } else {
         console.log(response.cause)
         const errors = response.cause.map((cause: any) => {
            switch (cause.code) {
               case '205': {
                  return ({
                     code: cause.code,
                     textfield: 'cardNumber',
                     description: 'Digite o número do seu cartão.'
                  })
               }
               case '208': {
                  return ({
                     code: cause.code,
                     textfield: 'cardExpirationMonth',
                     description: 'Escolha um mês.'
                  })
               }
               case '209': {
                  return ({
                     code: cause.code,
                     textfield: 'cardExpirationYear',
                     description: 'Escolha um ano.'
                  })
               }
               case '220': {
                  return ({
                     code: cause.code,
                     textfield: 'cardIssuerId',
                     description: 'Informe seu banco emissor.'
                  })
               }
               case '221': {
                  return ({
                     code: cause.code,
                     textfield: 'cardholderName',
                     description: 'Digite o nome e sobrenome.'
                  })
               }
               case '224': {
                  return ({
                     code: cause.code,
                     textfield: 'securityCode',
                     description: 'Digite o código de segurança.'
                  })
               }
               case 'E301': {
                  return ({
                     code: cause.code,
                     textfield: 'cardNumber',
                     description: 'Há algo de errado com esse número. Digite novamente.'
                  })
               }
               case 'E302': {
                  return ({
                     code: cause.code,
                     textfield: 'securityCode',
                     description: 'Confira o código de segurança.'
                  })
               }
               case '316': {
                  return ({
                     code: cause.code,
                     textfield: 'cardholderName',
                     description: 'Por favor, digite um nome válido.'
                  })
               }
               case '325': {
                  return ({
                     code: cause.code,
                     textfield: 'cardExpirationMonth',
                     description: 'Confira o mês.'
                  })
               }
               case '326': {
                  return ({
                     code: cause.code,
                     textfield: 'cardExpirationYear',
                     description: 'Confira o ano.'
                  })
               }
               default: {
                  return ({
                     code: cause.code,
                     textfield: 'geral',
                     description: 'Confira os dados.'
                  })
               }
            }
         });

         setErrorsCreditCardForm(errors);
      }
      setLoadingFalse();
   })
   console.log(resposta)
   return resposta;
}

export const sendPaymentInfo = async (creditCardPaymentInfo: any, paymentMethodSelect: string, billetPaymentInfo: any) => {
   //console.log(paymentMethodSelect)
   if (paymentMethodSelect === 'creditcard') {
      const copyPaymentInfo = { ...creditCardPaymentInfo };
      copyPaymentInfo['token'] = token;
      copyPaymentInfo['installments'] = (+copyPaymentInfo.installments);
      copyPaymentInfo['issuer_id'] = copyPaymentInfo['issuer_id'] === "" ? undefined : copyPaymentInfo['issuer_id'];
      //console.log(copyPaymentInfo)
      const creditCardResponse = (await axios.post(MICROSERVICE_MERCADOPAGO + 'payment-mercado-pago', copyPaymentInfo)).data
      console.log(creditCardResponse)

      switch (creditCardResponse.status) {
         case 'approved': {
            globalAlert(`Pronto, seu pagamento foi aprovado! No resumo, você verá a cobrança do valor como ${creditCardResponse.statement_descriptor}.`);
            break;
         }
         case 'in_proccess': {
            if (creditCardResponse.status_detail === 'pending_contingency') {
               globalAlert('Estamos processando o pagamento. Não se preocupe, em menos de 2 dias úteis informaremos por e-mail se foi creditado.');
            } else {
               globalAlert('Estamos processando seu pagamento. Não se preocupe, em menos de 2 dias úteis informaremos por e-mail se foi creditado ou se necessitamos de mais informação.')
            }
            break;
         }
         case 'rejected': {
            if (creditCardResponse.status_detail === 'cc_rejected_bad_filled_card_number') {
               throw new Error('Revise o número do cartão.');
            } else if (creditCardResponse.status_detail === 'cc_rejected_bad_filled_date') {
               throw new Error('Revise a data de vencimento.');
            } else if (creditCardResponse.status_detail === 'cc_rejected_bad_filled_other') {
               throw new Error('Revise os dados.');
            } else if (creditCardResponse.status_detail === 'cc_rejected_bad_filled_security_code') {
               throw new Error('Revise o código de segurança do cartão.');
            } else if (creditCardResponse.status_detail === 'cc_rejected_blacklist') {
               throw new Error('Não pudemos processar seu pagamento.');
            } else if (creditCardResponse.status_detail === 'cc_rejected_call_for_authorize') {
               throw new Error(`Você deve autorizar ao ${creditCardResponse.payment_method_id} o pagamento do valor ao Mercado Pago.`);
            } else if (creditCardResponse.status_detail === 'cc_rejected_card_disabled') {
               throw new Error(`Ligue para o ${creditCardResponse.payment_method_id} para ativar seu cartão. O telefone está no verso do seu cartão.`);
            } else if (creditCardResponse.status_detail === 'cc_rejected_card_error') {
               throw new Error('Não conseguimos processar seu pagamento.');
            } else if (creditCardResponse.status_detail === 'cc_rejected_duplicated_payment') {
               throw new Error('Você já efetuou um pagamento com esse valor. Caso precise pagar novamente, utilize outro cartão ou outra forma de pagamento.');
            } else if (creditCardResponse.status_detail === 'cc_rejected_high_risk') {
               throw new Error('Seu pagamento foi recusado. Escolha outra forma de pagamento. Recomendamos meios de pagamento em dinheiro.');
            } else if (creditCardResponse.status_detail === 'cc_rejected_insufficient_amount') {
               throw new Error(`O ${creditCardResponse.payment_method_id} possui saldo insuficiente.`);
            } else if (creditCardResponse.status_detail === 'cc_rejected_invalid_installments') {
               throw new Error(`O ${creditCardResponse.payment_method_id} não processa pagamentos em ${creditCardResponse.installments} parcelas.`);
            } else if (creditCardResponse.status_detail === 'cc_rejected_max_attempts') {
               throw new Error('Você atingiu o limite de tentativas permitido. Escolha outro cartão ou outra forma de pagamento.');
            } else if (creditCardResponse.status_detail === 'cc_rejected_other_reason') {
               throw new Error(`${creditCardResponse.payment_method_id} não processa o pagamento.`);
            } else {
               throw new Error(`Erro no Mercado Pago.`);
            }
         }
         default: {
            break;
         }
      }

   } else if (paymentMethodSelect === 'billet') {
      setLoadingTrue();
      axios.post(MICROSERVICE_MERCADOPAGO + 'payment-mercado-pago', billetPaymentInfo)
         .then(res => {
            setBilletUrl(res.data.transaction_details.external_resource_url)
            setLoadingFalse();
            return (res.data.transaction_details.external_resource_url)
         })
         .catch(err => {
            console.log('Billet error')
         });

   }
}

function setIssuers(status: number, response: any[]) {
   if (status == 200) {
      let issuerSelect = document.getElementById("issuer");
      response.forEach((issuer: { name: string; id: string; }) => {
         let opt = document.createElement("option");
         opt.text = issuer.name;
         opt.value = issuer.id;
         issuerSelect?.appendChild(opt);
      })

      getInstallments(
         document.getElementById("paymentMethodId").value,
         document.getElementById("transactionAmount").value,
         issuerSelect.value
      )
   } else {
      alert(`issuers method info error: ${response}`)
   }
}

function getIssuers(paymentMethodId: any) {
   window.Mercadopago.getIssuers(paymentMethodId, setIssuers);
}


function setPaymentMethod(status: number, response: any, copyPaymentInfo: { [x: string]: any; }, setCreditCardPaymentInfo: any, setInstallments: any) {
   // console.log(response);
   if (status === 200) {
      let paymentMethod = response[0];
      copyPaymentInfo['payment_method_id'] = paymentMethod.id;

      if (paymentMethod.additional_info_needed.includes("issuer_id")) {
         getIssuers(paymentMethod.id)
      } else {
         getInstallments(copyPaymentInfo, setCreditCardPaymentInfo, setInstallments);
      }
   } else {
      console.log(response);

   }
}

function getInstallments(copyPaymentInfo: { [x: string]: any; payment_method_id?: any; transaction_amount?: any; }, setCreditCardPaymentInfo: (arg0: any) => void, setInstallments: (arg0: any) => void) {

   window.Mercadopago.getInstallments({
      "payment_method_id": copyPaymentInfo.payment_method_id,
      "amount": parseFloat(copyPaymentInfo.transaction_amount)
   }, function (status: number, response: { payer_costs: any; }[]) {
      //console.log(response);
      if (status === 200) {
         //console.log(response);
         setInstallments(response[0].payer_costs);
         setCreditCardPaymentInfo(copyPaymentInfo);

      } else {
         globalAlert('Erro ao processar as parcelas. Tente novamente mais tarde.')
      }
   });
}

function guessPaymentMethod(copyPaymentInfo: { [x: string]: any; }, setCreditCardPaymentInfo: any, setInstallments: any, cardNumber: string, setCardNumber: any) {
   // let cardnumber = copyPaymentInfo.cardNumber;
   let bin = cardNumber.substring(0, 6);

   window.Mercadopago.getPaymentMethod({
      "bin": bin
   }, (status: number, response: { id: any; }[]) => setPaymentMethod(status, response, copyPaymentInfo, setCreditCardPaymentInfo, setInstallments));

};

export const handlePaymentInfo = (e: any, creditCardPaymentInfo: any, setCreditCardPaymentInfo: any, setInstallments: any, cardNumber: any, setCardNumber: any) => {
   const copyPaymentInfo = { ...creditCardPaymentInfo };

   if (e.installments) {
      copyPaymentInfo['installments'] = e.installments;
   } else {
      if (e.currentTarget.name === 'cardNumber') {
         setCardNumber(e.currentTarget.value)
      } else {
         copyPaymentInfo[e.currentTarget.name] = e.currentTarget.value;
      }

      if (cardNumber && cardNumber.length >= 6) {
         guessPaymentMethod(copyPaymentInfo, setCreditCardPaymentInfo, setInstallments, cardNumber, setCardNumber);
      } else {
         setCreditCardPaymentInfo(copyPaymentInfo);
      }
   }
   setCreditCardPaymentInfo(copyPaymentInfo);
}

export const handleFormInfo = (e: any, formPaymentInfo: any, setFormPaymentInfo: any) => {
   const copyPaymentInfo = { ...formPaymentInfo };
   copyPaymentInfo[e.currentTarget.name] = e.currentTarget.value;
   setFormPaymentInfo(copyPaymentInfo);
}