import React from 'react'
import styles from './DrawerRight.module.scss'
import { useSelector } from "react-redux"
import Drawer from "@material-ui/core/Drawer"
import DrawerCart from './DrawerCartContent/DrawerCartContent'
import { toggleDrawerRight } from '../../../store/actions'

type DrawerRightType = {
  drawerRightOpen: boolean
}

const RightDrawerSwitch:React.FC<any> = ({drawerCartOpen}) => {
  if(drawerCartOpen){
    return <DrawerCart/>
  }
  return null
}

const DrawerRight:React.FC<DrawerRightType> = ({drawerRightOpen}) => {
  const drawerCartOpen = useSelector((state: any) => state.drawers.isCartOpen)
  return (
    <Drawer
      anchor={"right"}
      open={drawerRightOpen}
      onClose={toggleDrawerRight}
      data-testid="drawer-category"
      classes={{
        paper: styles.mainContainer,
      }}
    >
      <RightDrawerSwitch drawerCartOpen={drawerCartOpen} />
    </Drawer>
  )
}

export default DrawerRight
