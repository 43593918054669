import React from 'react'
import styles from '../LoginComponent.module.scss'
import '../LoginComponent.scss'
import { ClassNamesFn } from 'classnames/types';
import { Link } from 'gatsby'
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button';

type accountStepType = {
  activeStep: number
  email: string
  password: string
  handleChange: any
  cx: ClassNamesFn
  loginUser: any
  handleClickShowPassword: () => void
  showPassword: boolean
  forgotPassword: () => void
}
const accountStep: React.FC<accountStepType> = ({ activeStep, cx, email, password,handleChange, loginUser, forgotPassword, showPassword, handleClickShowPassword }) => {
  const paymentMethodClass = cx({
    displayBlock: activeStep === 1,
    displayNone: activeStep !== 1
  });
  return (
    <div className={paymentMethodClass}>
      <div style={{ textAlign: 'center', justifyContent: 'center' }}>
        <b style={{ fontSize: '16px' }}>Digite sua senha</b>
        <br />
        <div >
          <TextField
            value={email}
            variant="outlined"
            margin="dense"
            fullWidth
            id="email"
            label="Email"
            style={{ width: '90%', marginTop: '15px' }}
            classes={{ root: "textfieldLogin" }}
            onChange={(e) => { handleChange('email', e.target.value) }}
          />
        </div>
        <div >
          <TextField
            type={showPassword ? 'text' : 'password'}
            value={password}
            variant="outlined"
            margin="dense"
            fullWidth
            id="password"
            label="Senha"
            style={{ width: '90%', margin: '15px 0' }}
            classes={{ root: "textfieldLogin" }}
            onChange={(e) => { handleChange('password', e.target.value) }}
            onKeyPress={(e) => { e.key === 'Enter' && loginUser() }}
            InputProps={{
              endAdornment: (
                < InputAdornment position="end" >
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ), "aria-label": "password" 
            }}
          />
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => { loginUser() }}
            classes={{ root: styles.buttonLogin }}
          >
            Logar
        </Button>
        </div>
        <div>
          <Button disableTouchRipple onClick={()=>{forgotPassword()}} size="small" style={{ fontSize: '12.5px'}}>
            Esqueci minha senha
          </Button>
        </div>
      </div>
    </div>
  )
}

export default accountStep
