import React, { useState } from "react"
import styles from "./CategoryDrawerContent.module.scss"
import { useSelector } from "react-redux"
import { toggleDrawerLeft } from "../../../../store/actions"
import Button from '@material-ui/core/Button'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import DrawerIcons from './components/DrawerIcons'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import categoriesTree from "../../../../data/categoriesTree.json"
import TreeView from "@material-ui/lab/TreeView"
import NestedCategoryItem from './components/NestedCategoryItem'
type DrawerCategoryType = {}

const DrawerCategory: React.FC<DrawerCategoryType> = () => {
  const drawers = useSelector((state: any) => state.drawers)
  const [expanded, setExpanded] = useState([])
  let opened = '';


  const handleToggleExpanded = (event: React.ChangeEvent<{}>, nodeIds: any) => {
    setExpanded(nodeIds)
  }
  return (

    <div className={styles.drawerCategoryMain}>
      <div className={styles.gridMenu}>
        <div className={styles.closeButton}></div>
        <div className={styles.menuPrincipal}>
          <span>MENU</span>
        </div>
        <div className={styles.closeButton}>
          <Button
            onClick={toggleDrawerLeft}
            disableTouchRipple
            color="primary"
            style={{ minWidth: "20px", height: "20px", padding: 0 }}
          >
            <CloseRoundedIcon fontSize="small" />
          </Button>
        </div>
      </div>
      <DrawerIcons />
      <div className={styles.categoriesDisplayMain}>
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
          onNodeToggle={handleToggleExpanded}
          disableSelection
        >
          {categoriesTree.map((category: any) => {
            return (
              <div key={category.id}>
                <NestedCategoryItem
                  key={category.id}
                  category={category}
                  expanded={expanded}
                />

              </div>
            )
          })}
        </TreeView>
      </div>
      <div className={styles.divDuvidas}>DÚVIDAS</div>
    </div>
  )
}

export default DrawerCategory
