import { createMuiTheme } from '@material-ui/core/styles';

let theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Montserrat',
            'sans-serif',
        ].join(','),
        // fontSize: 14,
    },
    palette: {
        primary: {
            main: '#e8927c',
            dark: '#d1836f',
            contrastText: '#fff',
        },
        secondary: {
            main: '#fbece8',
            contrastText: '#595959',
        },
        text: {
            primary: '#595959'
        }
    },
    shape: {
        borderRadius: 50,
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "12px",
                color: "#595959",
                backgroundColor: "rgba(251, 236, 232, 0.90)",
                textAlign: 'center',
                margin: '0',
                borderRadius: '5px',
                padding: '15px 20px',
                maxWidth: '250px',
            },
            arrow: {
                color: "rgba(251, 236, 232, 0.90)",
            },
            tooltipPlacementTop: {
                margin: '0 0 5px 0 !important'
            },
            popper: {
                cursor: 'default',
                pointerEvents: 'auto'
            }
        },
        MuiOutlinedInput: {
            root: {
                '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                    borderColor: '#e8927c',
                    '@media (hover: none)': {
                        borderColor: '#fbece8',
                    },
                },
                '&$focused $notchedOutline': {
                    borderColor: '#e8927c',
                    borderWidth: 1,
                },
            },
        },
        MuiIconButton: {
            root: {
                backgroundColor: 'transparent !important',
                padding: '0px 10px'
            }
        },
        MuiStepIcon: {
            root: {
                color: "transparent",
                border: '2px solid lightgray',
                borderRadius: '50px',
                fontSize: '16px',
            },
            text: {
                fill: 'transparent',
            },
            active: {
                color: "#e8927c",
                border: '#e8927c',
                fontSize: '20px',
            },
            completed: {
                color: "#e8927c",
                border: '#e8927c',
                fontSize: '20px',
            }
        },
        MuiStepConnector: {
            alternativeLabel: {
                top: 10,
                left: 'calc(-50% + 15px)',
                right: 'calc(50% + 15px)',
            },
            active: {
                '& $line': {
                    borderColor: '#e8927c',
                },
            },
            completed: {
                '& $line': {
                    borderColor: '#e8927c',
                },
            },
            line: {
                borderColor: 'lightgray',
                borderTopWidth: '1.5px !important',
            },
        },
    },
    spacing: 1,
    props: {
        MuiButtonBase: {
            TouchRippleProps: {
                classes: {
                    root: 'CustomizeTouchRipple'
                }
            }
        }, 
    }
});

//theme.spacing(2);

export default theme;