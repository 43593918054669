import React, { useState, useEffect } from 'react'
import './Search.scss'
import styles from './Search.module.scss'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import MenuIcon from "@material-ui/icons/MenuRounded"
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined"
import Badge from "@material-ui/core/Badge"
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import IconButton from '@material-ui/core/IconButton'
import { Link, navigate } from "gatsby"
import LogoNavbarVJ from "../../images/logoVj.inline.svg"
import Modal from '@material-ui/core/Modal'
import ImageComponent from '../ImageComponent/SingleImageComponent'
import { useSelector } from "react-redux"
import {
  toggleDrawerLeft,
  toggleDrawerRight,
  toggleSearch
} from "../../store/actions"
import { SOCKET_NODES, MICROSERVICE_GET_CATEGORY_PRODUCTS } from '../../../userglobals'
import io from "socket.io-client"
import axios from 'axios'

let socket: SocketIOClient.Socket;

type SearchNavbarType = {
  count: number
}

const SearchNavbar: React.FC<SearchNavbarType> = ({ count }) => {
  const isSearchOpen = useSelector((state: any) => state.isSearchOpen);
  const [searchValue, setSearchValue] = useState('');
  const [categoryResponseList, setCategoryResponseList] = useState([]);
  const [productsResponseList, setProductsResponseList] = useState([]);

  useEffect(() => {
    socket = io(SOCKET_NODES, { transports: ['websocket'] });
  }, []);

  const handleSearch = (value: string) => {
    setSearchValue(value)
    if (value.length >= 3) {
      socket.emit('search-input', { searchInput: value, productNumber: 3 }, ({ categoriesSuggested, productsSuggested }) => {
        setCategoryResponseList(
          categoriesSuggested.categorySuggestions[0].options
        )
        setProductsResponseList(productsSuggested)
      });
    } else {
      setCategoryResponseList([]);
      setProductsResponseList([]);
    }
  }

  const redirectToSearchpage = async () => {
    try {
      const response = await axios.post(MICROSERVICE_GET_CATEGORY_PRODUCTS + 'get-full-search-result', { searchInput: searchValue });
      navigate('/busca/', {
        state: { products: response.data, searchInput: searchValue },
      })
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Modal
      open={isSearchOpen}
      onClose={toggleSearch}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ height: 'fit-content', backgroundColor: '#fff', boxShadow: '0 3px 3px 0 rgba(89, 89, 89, 0.4)' }}
      BackdropProps={{
        invisible: true
      }}
    >
      <>
        <div style={{ backgroundColor: '#fbece8', display: 'flex', padding: '0 5px 0 15px', height: '50px' }}>
          <IconButton
            edge="start"
            aria-label="menu"
            onClick={toggleDrawerLeft}
            data-testid="button-toggle-category-drawer"
          >
            <MenuIcon />
          </IconButton>
          <div className={styles.logoWrapper}>
            <Link to="/" aria-current="page" rel="Go to home page" style={{ display: 'flex', alignItems: 'center' }}>
              <LogoNavbarVJ fill="#e8927c" alt="Logomarca da Virtual Joias" />
            </Link>
          </div>
          <IconButton
            edge="start"
            aria-label="cart"
            data-testid="button-toggle-cart-drawer"
            onClick={toggleDrawerRight}
          >
            <Badge
              badgeContent={count}
              color="primary"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              classes={{ anchorOriginBottomRightCircle: styles.badge }}
              overlap="circle"
              showZero
            >
              <LocalMallOutlinedIcon />
            </Badge>
          </IconButton>
        </div>
        <div className={styles.searchDiv}>
          <TextField
            className="textfieldSearch"
            style={{ width: '83%' }}
            placeholder="O que você procura?"
            id="input-with-icon-textfield"
            autoComplete="off"
            onChange={(e) => handleSearch(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                if (productsResponseList.length > 0) {
                  toggleSearch();
                  redirectToSearchpage()
                } else if (categoryResponseList.length > 0) {
                  toggleSearch();
                  navigate(`${categoryResponseList[0]._source.slug}/`)
                }
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start" classes={{ positionStart: styles.inputAdornment }}>
                  <IconButton
                    className={styles.button}
                    edge="start"
                    aria-label="search"
                    data-testid="button-toggle-collabse-toolbar"
                    onClick={() => {
                      if (productsResponseList.length > 0) {
                        toggleSearch();
                        redirectToSearchpage()
                      } else if (categoryResponseList.length > 0) {
                        toggleSearch();
                        navigate(`${categoryResponseList[0]._source.slug}/`)
                      }
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <IconButton
            className={styles.buttonClose}
            onClick={toggleSearch}
            edge="start"
            aria-label="closesearch"
          >
            <CloseRoundedIcon fontSize='small' />
          </IconButton>
        </div>
        {
          (categoryResponseList.length > 0 || productsResponseList.length > 0) &&
          <div>
            {categoryResponseList.map((item: any, idx) => {
              return (
                <div onClick={() => {
                  toggleSearch();
                  navigate(`${item._source.slug}`)
                }} style={{ backgroundColor: 'white', padding: '5px 15px', borderTop: '2px solid #f8f7f6' }} key={idx}>
                  {item._source.name}
                </div>
              )
            })}
            {
              (productsResponseList.length > 0) &&
              <>
                <div style={{ display: 'flex', backgroundColor: '#fbece8', padding: '5px', justifyContent: 'center', borderTop: '2px solid #f8f7f6' }}> SUGESTÃO DE PRODUTOS</div>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 8px', backgroundColor: '#f8f7f6' }}>
                  {productsResponseList.map((item: any, idx) => {
                    return (
                      <div key={idx} style={{ marginLeft: '2px', textAlign: 'center', maxWidth: '33.3%' }} onClick={() => {
                        toggleSearch();
                        navigate(`/${item._source.slug}`)
                      }}>
                        <ImageComponent imgUrl={"https://magento-virtualjoias.com/media/catalog/product" + item._source.image} altImg={item._source.name} />
                        <div style={{ backgroundColor: 'white', minHeight: '95px', lineHeight: 'normal', padding: '6px 3px', borderTop: '2px solid #f8f7f6', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                          <span style={{ fontSize: '11px' }}>{item._source.name}</span>
                          <div>
                            <span style={{ fontSize: '10px', color: '#c2c2c2', textDecoration: 'line-through' }}>DE: R$ {item._source.price.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</span> <br />
                            <span style={{ fontSize: '12px', fontWeight: 'bold', color: '#e8927c' }}>Por: R$ {item._source.discount_price.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</span>
                          </div>
                        </div>
                      </div>);
                  })}
                </div>
                <div onClick={() => { toggleSearch(); redirectToSearchpage() }} style={{ display: 'flex', backgroundColor: '#fff', padding: '8px', justifyContent: 'center', color: '#e8927c', textTransform: 'uppercase' }}>Ver todos os produtos</div>
              </>
            }
          </div>
        }
      </>
    </Modal>
  )
}

export default SearchNavbar
