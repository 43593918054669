import React, { useState, ReactNode } from "react"
import Header from "../Headers/header"
import SimpleHeader from '../Headers/simpleHeader'
import Footer from "../Footers/Footer/Footer"
import FooterCheckout from '../Footers/FooterCheckout/FooterCheckout'
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import { useSelector } from 'react-redux'
import theme from "../../themes/defaultTheme"
import Container from "@material-ui/core/Container"
import DrawerLeft from '../Drawers/DrawerLeft/DrawerLeft'
import DrawerRight from "../Drawers/DrawerRight/DrawerRight"
import GlobalLoading from '../Loadings/GlobalLoading'
import Alert from '../Alert/Alert'

type LayoutType = {
  children: ReactNode
  location: Location
}

const Layout: React.FC<LayoutType> = ({ children, location }) => {
  const drawerLeftOpen = useSelector((state: any) => state.drawers.isDrawerLeftOpen);
  const drawerRightOpen = useSelector((state: any) => state.drawers.isDrawerRightOpen);
  const alertMessage = useSelector((state: any) => state.alertMessage);
  const alertButton = useSelector((state: any) => state.alertButton);
  const isAlertOpen = useSelector((state: any) => state.isAlertOpen);
  const isLoading = useSelector((state: any) => state.isLoading);

  const [layoutState, setLayoutState] = useState({
    isDrawerCategoryOpen: false,
    isDrawerSearchOpen: false,
    isDrawerRegisterOpen: false,
    loginIsVisible: false,
    isMounted: false,
  })

  //carregamento
  const toggleLoginInToolbar = () => {
    setLayoutState((prevState: any) => ({
      ...prevState,
      loginIsVisible: !layoutState.loginIsVisible,
    }))
  }

  if (location && location.pathname.match(/^\/checkout/) || location && location.pathname.match(/^\/sucesso/) || location && location.pathname.match(/^\/fracasso/)) {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <div className="content">
            {isLoading && <GlobalLoading isLoading={isLoading} />}
            {isAlertOpen &&
              <Alert isAlertOpen={isAlertOpen} message={alertMessage} button={alertButton} />
            }
            <SimpleHeader />
            {/* {rastreioAlert && <CepAlert rastreioAlert={rastreioAlert} />} */}
            <Container component="main">{children}</Container>
          </div>
          <FooterCheckout />
        </div>
      </MuiThemeProvider>
    )
  } else if (location && location.pathname.match(/^\/cadastro/)) {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {isLoading && <GlobalLoading isLoading={isLoading} />}
          {isAlertOpen &&
            <Alert isAlertOpen={isAlertOpen} message={alertMessage} button={alertButton} />
          }
          <SimpleHeader />
          {/* {rastreioAlert && <CepAlert rastreioAlert={rastreioAlert} />} */}
          <Container component="main">{children}</Container>
        </div>
      </MuiThemeProvider>
    )
  } else if (location && location.pathname.match(/^\/minha-conta/)) {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {isLoading && <GlobalLoading isLoading={isLoading} />}
          {isAlertOpen &&
            <Alert isAlertOpen={isAlertOpen} message={alertMessage} button={alertButton} />
          }
          <Header
            loginIsVisible={layoutState.loginIsVisible}
            toggleLoginInToolbar={toggleLoginInToolbar}
          />
          {/* {rastreioAlert && <CepAlert rastreioAlert={rastreioAlert} />} */}
          <Container component="main">{children}</Container>
        </div>
      </MuiThemeProvider>
    )
  } else {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <div className="content">

            <Header
              loginIsVisible={layoutState.loginIsVisible}
              toggleLoginInToolbar={toggleLoginInToolbar}
            />
            {isLoading && <GlobalLoading isLoading={isLoading} />}
            {isAlertOpen &&
              <Alert isAlertOpen={isAlertOpen} message={alertMessage} button={alertButton} />
            }
            {drawerLeftOpen && <DrawerLeft drawerLeftOpen={drawerLeftOpen} />}
            {drawerRightOpen && <DrawerRight drawerRightOpen={drawerRightOpen} />}
            <Container component="main">{children}</Container>
          </div>
          <Footer />
        </div>
      </MuiThemeProvider>
    )
  }
}

export default Layout
