import React from "react"
import Navbar from '../Navbars/Navbar'

type HeaderType = {
   loginIsVisible: boolean
   toggleLoginInToolbar: () => void
}

const header: React.FC<HeaderType> = ({
   loginIsVisible,
   toggleLoginInToolbar,
}) => {
   return <Navbar
      loginIsVisible={loginIsVisible}
      toggleLoginInToolbar={toggleLoginInToolbar}
   />
}

export default header