import React from 'react'
import '../Footer.scss'
import styles from '../Footer.module.scss'
import SingleLazyImageComponent from '../../ImageComponent/SingleLazyImageComponent'
import { MAGENTO_URL } from '../../../../userglobals'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ClassNames from 'classnames/bind'


let cx = ClassNames.bind(styles)

type shipmentMethodsType = {

}

const shipmentMethods: React.FC<shipmentMethodsType> = () => {
   const imgUrl = `${MAGENTO_URL}media/icons/formas-de-envio.svg`

   // let className = cx({
   //    ship: smallDevice,
   //    paddingLeft: !smallDevice
   // });

   return (
      <div className={styles.shipmentMethods}>
         <ExpansionPanelSummary id="shipmentMethodsMobile" className={ClassNames(styles.expansionPanel)}>
            FORMAS DE ENVIO
            </ExpansionPanelSummary>
         <div className={styles.shipmentDiv}>
            <SingleLazyImageComponent imgUrl={imgUrl} altImg={'Formas de Envio'} widthContainer={114} heightContainer={26} widthLoading={20} heightLoading={20} />
         </div>
      </div >
   )
}

export default shipmentMethods