import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon16 from '../images/favicon16.png'
import favicon32 from "../images/favicon32.png"
import favicon64 from "../images/favicon64.png"
import iosicon from "../images/iosicon.png"

type MetaItem = {
   name: string;
   content: string;
};

type SEOType = {
   title?: string;
   description?: string;
   author?: string;
   keywords?: string;
   meta?: MetaItem[];
   schemaMarkup ?: any
};

type siteData = {
   site: {
      siteMetadata: {
         title: string
         description: string
         author: string
         keywords: string
      }
   }
};

const SEO: React.FC<SEOType> = ({ title, description, author, keywords, meta, schemaMarkup }) => {
   const { site }: siteData = useStaticQuery(
      graphql`
      query {
         site {
            siteMetadata {
               title
               description
               author
               keywords
            }
         }
      }`
   )

   const siteTitle = title || site.siteMetadata.title;
   const siteDescription = description || site.siteMetadata.description;
   const siteAuthor = author || site.siteMetadata.author;
   const siteKeywords = keywords || site.siteMetadata.keywords;

   const metaData = [
      {
         name: "description",
         content: siteDescription,
      },
      {
         name: "og:title",
         content: siteTitle,
      },
      {
         name: "og:description",
         content: siteDescription,
      },
      {
         name: "twitter:title",
         content: siteTitle,
      },
      {
         name: "twitter:description",
         content: siteDescription,
      },
      {
         name: `twitter:creator`,
         content: siteAuthor,
      },
      {
         name: "keywords",
         content: siteKeywords,
      },
      {
         name: "robots",
         content: "index,follow"
      }
   ].concat(meta || []);

   return (
      <Helmet
         htmlAttributes={{ lang: "pt-br" }}
         title={siteTitle}
         meta={metaData}
         link={[
            { rel: "icon", type: "image/png", sizes: "16x16", href: `${favicon16}` },
            { rel: "icon", type: "image/png", sizes: "32x32", href: `${favicon32}` },
            { rel: "shortcut icon", type: "image/png", href: `${favicon64}` },
            { rel: "apple-touch-icon", href: `${iosicon}` }
         ]}
      >
         {
            schemaMarkup
            &&
            <script type="application/ld+json">
               {JSON.stringify(schemaMarkup)}
            </script>
         }
      </Helmet>
   )
}

export default SEO

